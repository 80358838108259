import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Invoice } from '../../_models/Invoice';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { TopFormSearchOption } from '../TopFormSearch';
import TopForm, { TopFormState, StatusOption } from '../TopForm';
import handleApiErrors from '../../common/handleApiErrors';
import ReactTable, { Column, SortingRule } from 'react-table';
import moment from 'moment';
import {
  CircularProgress,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Auth } from "aws-amplify";
import EditInvoicePopup from '../EditInvoicePopup'
import { navigate } from '@reach/router';
import useCustomSnak from '../helpers/handleSnackbars';

const searchOptions: TopFormSearchOption[] = [
  { label: 'Contract', value: 'contract.contractnumber' },
  { label: 'RO#', value: 'contract.ro_number' },
  //{ label: 'License Plate', value: 'vehicle.licenseplate' },
];

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    edit_bt: {
      float: 'right',
      minWidth: '25px',
      fontSize: '12px',
    },
    invoice_stat_div: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "space-between",
      textAlign: "left"
    },
    invoice_stat: {
      minWidth: "180px",
      margin: "auto"
    },
    paymentlink_reactivation :{
      background: "forestgreen",
      color: "white",
      '&:hover': { 
        backgroundColor: 'darkgreen',
      },
    },
  })
)

export const statusOptions: StatusOption[] = [
  { name: 'Charged', value: 'Charged' },
  { name: 'Created', value: 'Created' },
  { name: 'Declined', value: 'Declined' },
  { name: 'Missing Credit Card', value: 'missing_cc_token' },
  { name: 'Manually Charged', value: 'manually_charged' },
  { name: 'Customer Dispute - Lost', value: 'customer_dispute_lost' },
  { name: 'Customer Dispute - Won', value: 'customer_dispute_won' },
  { name: 'Customer Dispute - Initiated', value: 'customer_dispute_initiated' },
  { name: 'Manually Refunded', value: 'manually_refunded' },
  { name: 'Void', value: 'void' }

];
export const statusOptionsDict: any = {
  "Charged": "Charged",
  "Created": "Created",
  "Declined": "Declined",
  "missing_cc_token": "Missing Credit Card",
  "manually_charged": "Manually Charged",
  "customer_dispute_won": "Customer Dispute - Won",
  "customer_dispute_lost": "Customer Dispute - Lost",
  "customer_dispute_initiated": "Customer Dispute - Initiated",
  "manually_refunded": "Manually Refunded",
  "void": "Void"
}
interface PageState {
  readonly page: number;
  readonly pageSize: number;
  readonly sortingRules: SortingRule[];
}
const defaultPageState: PageState = {
  page: 0,
  pageSize: 10,
  sortingRules: [{
    id: 'id',
    desc: false,
  }],
};
export default function Invoices() {
  const { enqueueSnackbar } = useSnackbar();
  const handleCustomSnak = useCustomSnak();
  const [csvIsLoading, setCsvIsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const [searchValue, setSearchValue] = useState<string>('');
  const [topFormState, setTopFormState] = useState<TopFormState>({
    dealershipId: 0,
    endDate: null,
    searchInput: '',
    searchType: searchOptions[0],
    startDate: null,
    statusSelection: undefined,
  });

  // fetch stuff //////////////////////////////////////////////////////////////
  // invoices
  const [[invoices, totalInvoicesCount], setContracts] = React.useState<[Invoice[], number]>([[], 0]);
  const searchInvoices = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getInvoices();
    setContracts([response.body, response.totalRecordCount]);
    setIsLoading(false);
  }

  // handlers /////////////////////////////////////////////////////////////////
  const handleCsvButton = async (): Promise<void> => {
    setCsvIsLoading(true);
    const response = await getInvoices(false, { headers: { accept: 'text/csv' }, responseType: 'text' });
    // fileDownload(response, 'data.csv');
    const currentDate = moment().format('YYYYMMDD-hhmmss')
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "InvoiceSummary" + currentDate + fileExtension);
    setCsvIsLoading(false);
  };


  const handleTableFetchData = (state: {
    page: number;
    pageSize: number;
    sorted: SortingRule[];
  }): void => {
    setPageState({
      ...pageState,
      page: state.page,
      pageSize: state.pageSize,
      sortingRules: state.sorted,
    });
  };

  const getInvoices = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
    const queries: Array<{ name: string; value: string }> = [];
    if (paginate) {
      queries.push({ name: 'limit', value: `${pageState.pageSize}` });
      queries.push({ name: 'offset', value: `${pageState.pageSize * pageState.page}` });
    }

    if (topFormState.dealershipId !== 0)
      queries.push({ name: 'contract.dealershipid', value: `${topFormState.dealershipId}` });
    if (topFormState.statusSelection != null)
      queries.push({ name: 'status', value: topFormState.statusSelection.value });
    if (topFormState.searchType.value !== '' && topFormState.searchInput !== '')
      queries.push({
        name: topFormState.searchType.value,
        value: topFormState.searchInput,
      });
    if (topFormState.endDate != null)
      queries.push({ name: 'lt-attempted_charge_date', value: moment(topFormState.endDate).format('YYYY-MM-DD').toString() });
    if (topFormState.startDate != null)
      queries.push({ name: 'gte-attempted_charge_date', value: moment(topFormState.startDate).startOf('month').format('YYYY-MM-DD').toString() });

    // sort
    let i: number = 0;
    for (let sortingRule of pageState.sortingRules)
      queries.push({
        name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`,
        value: `${sortingRule.id}`,
      });

    let path: string = '/invoices';
    if (queries.length >= 1) {
      const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
      path = `${path}?${paramStrings.join("&")}`;
    }

    try {
      return await API.get(getAPIName(), path, apiInit);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  let pages: number =
    totalInvoicesCount === 0 || pageState.pageSize === 0
      ? 0
      : Math.trunc(totalInvoicesCount / pageState.pageSize);
  if (
    pages > 0 &&
    totalInvoicesCount > pageState.pageSize &&
    totalInvoicesCount % pageState.pageSize > 0
  )
    ++pages;
  const classes = useButtonStyles();
  const [editInvoiceStatFlag, setEditInvoiceStatFlag] = useState(false)
  const [invoiceToEdit, setInvoiceToEdit] = useState({})
  const [updatedInvoiceStatus, setUpdatedInvoiceStatus] = useState('')
  const [updateExecuted, setUpdateExecuted] = useState(false)
  const editInvoiceStatus = (invoice: Invoice) => {
    setEditInvoiceStatFlag(true)
    setInvoiceToEdit(invoice)
    setUpdatedInvoiceStatus(invoice.status)
  }
  const toggleInvoiceEditPopup = () => {
    setEditInvoiceStatFlag(!editInvoiceStatFlag);
  }
  const handlePaymentLinkReactivation = async (paymentLinkId : string) =>{
    try {
      setIsLoading(true)
      let path = '/api/v1/paymentlink/' + paymentLinkId;
      const response: any = await API.patch(getAPIName(), path,
        {
          body: {"active":"true"}
        });
      handleCustomSnak(`PaymentLink has been reactivated`, 'success')
      setIsLoading(false)
      setUpdateExecuted(!updateExecuted)
    }
    catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      setIsLoading(false)
    }
  }
  const columns: Column[] = [
    {
      Header: 'Invoice Number',
      accessor: 'id',
      Cell: (props: any) => {
        return props.original.bucketpath && (
          <a
            target="_blank"
            href={
              'https://tollaid-emailtemplates.s3.us-east-2.amazonaws.com/prod/' +
              props.original.bucketpath
            }
          >
            {props.original.id}
          </a>
        )
      }

    },
    {
      Header: 'Contract #',
      accessor: 'contract.contractnumber'
    },
    {
      Header: 'RO #',
      accessor: 'contract.ro_number'
    },
    {
      Header: 'First Name',
      accessor: 'contract.customer.firstname'
    },
    {
      Header: 'Last Name',
      accessor: 'contract.customer.lastname'
    },
    {
      Header: 'Email',
      accessor: 'contract.customer.emailaddress'
    },
    {
      accessor: (row: any) => row.amount != null ? `$${(+row.amount).toFixed(2)}` : '—',
      Header: 'Amount',
      id: 'amount',
    },
    {
      accessor: (row: any) => row.insertedtimestamp == null
      ? '—'
      : moment(row.insertedtimestamp).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Invoice Date',
      id: 'insertedtimestamp',
    },
    {
      accessor: (row: any) => row.attempted_charge_date == null
        ? '—'
        : moment(row.attempted_charge_date).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Attempted Charge Date',
      id: 'attempted_charge_date',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: any) => {
        return props.original.status && (
          <div className={classes.invoice_stat_div}>
            <div className={classes.invoice_stat}>
              {statusOptionsDict[props.original.status] || '-'}
            </div>
          </div>
        )
      }
    },
    {
      Header: 'PaymentLink Status',
      accessor: 'paymentlink',
      sortable: false,
      Cell: (props: any) => {
        var paymentlinks = props.original.paymentlink
        if (paymentlinks.length > 0) {
          var pl = paymentlinks.sort((a: any, b: any) => b.id - a.id)[0]
        }
        return ( pl?.status && props.original.status != 'Charged' ) ? ( (pl?.status == 'Created' && props.original.status != 'Charged')  ? (
          <Button onClick={() => window.open(pl?.payment_link_url)} color="primary" size="small" variant="contained" > Pay  </Button>
          ) : 
          ( pl?.status == 'Expired' ? 
          <Button onClick={() => handlePaymentLinkReactivation(pl.payment_link_id)}  size="small" variant="contained" className={classes.paymentlink_reactivation} > Reactivate  </Button>
          : pl.status)) 
          : "-"
      }
    },
    {
      Header: 'Dealership',
      accessor: 'contract.dealership.name'
    },
    {
      Header: 'Group',
      accessor: 'contract.dealership.dealershipgroup.name'
    },
    {
      Header: 'Payment Gateway Fee',
      accessor: 'pgw_fee_total'
    },
    {
      accessor: "invoiceinformation.comment",
      sortable: false,
      Header: 'Notes',
      Cell: (props: any) => props.original.invoiceinformation?.comment ? props.original.invoiceinformation.comment : '-'
    },
    {
      Header: 'Edit',
      id: 'edit',
      sortable: false,
      Cell: (props: any) => {
        return (
          < Button
            variant="outlined"
            onClick={() => editInvoiceStatus(props.original)}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        )
      }
    }
  ];
  const updateInvoice = async (invoice: any, updated_status: string): Promise<void> => {
    let apiName = getAPIName();
    let path = `/invoices`;
    try {
      var current_session_data = await Auth.currentAuthenticatedUser();
      var user_name = null
      if (current_session_data.attributes.name || current_session_data.attributes.email) {
        user_name = current_session_data.attributes.name || current_session_data.attributes.email
      }
      const response = await API.patch(apiName, path + "/" + invoice.id, { body: { 'status': updated_status, 'updated_by': user_name } });
      if (response.body[1] > 0) {
        enqueueSnackbar('invoice changes saved!', { variant: 'success' });
      }
      else {
        handleApiErrors("cannot update the status", enqueueSnackbar, 'invoice')
      }
      setUpdateExecuted(!updateExecuted)
      toggleInvoiceEditPopup();
      setInvoiceToEdit({})
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'invoice');
    }
  };

  useEffect(() => { searchInvoices(); }, [searchValue, pageState, updateExecuted]);

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div className="Invoices">
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4" align="left">
            Invoices
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button size="small" variant="contained" color="primary" onClick={() => navigate('/subscriptioninvoice')}>
            Subscription Invoices
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button size="small" variant="contained" color="primary" onClick={handleCsvButton}>
            {!csvIsLoading ? 'Download Excel' :
              <CircularProgress size={24} />
            }
          </Button>
        </Grid>
      </Grid>

      <TopForm
        onChange={setTopFormState}
        onSearch={searchInvoices}
        searchOptions={searchOptions}
        state={topFormState}
        statusOptions={statusOptions}
      />

      <Grid container>
        <Grid xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={invoices}
            defaultPageSize={10}
            defaultSorted={defaultPageState.sortingRules}
            loading={isLoading}
            manual
            onFetchData={handleTableFetchData}
            pages={pages}
          />
        </Grid>
      </Grid>

      <EditInvoicePopup
        invoice={invoiceToEdit}
        isOpen={editInvoiceStatFlag}
        text="Edit Invoice"
        handleSave={updateInvoice}
        toggleOpen={toggleInvoiceEditPopup}
        updatedInvoiceStatus={updatedInvoiceStatus}
        setUpdatedInvoiceStatus={setUpdatedInvoiceStatus}
        updateExecuted={updateExecuted}
        setUpdateExecuted={setUpdateExecuted}
      />
    </div>
  );
}

